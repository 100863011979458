import { faPenNib } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Col,
  Form,
  FormInstance,
  message,
  Modal,
  Popover,
  Upload,
} from "antd";
import { RcFile, UploadFile } from "antd/lib/upload";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { LayoutRequest } from "../../../../requests/PageRequest";
import { StyleProps } from "../../../../store/LayoutStore/LayoutInterface";
import { UploadOutlined } from "@ant-design/icons";
import StyleModalComponent from "../components/StyleModal";
import {
  createUrl,
  planMessage,
  widgetStyles,
} from "../../../../config/Global";
import useStore from "../../../../store";
import { CONSTANT } from "../../../../config/Constant";

interface formInterface {
  form: FormInstance;
  name: any;
  label: string;
  onChange: () => void;
  layout_id: any;
  setShow?: (data: any) => void;
  layoutIdFlag?: boolean;
  restField: any
}

const MixVideoElement: React.FC<formInterface> = observer((props) => {
  const { form, onChange, name, label, layout_id, setShow, layoutIdFlag, restField } = props;
  const [styleProps, setStyleProps] = useState<StyleProps>({
    styles: [],
    type: "",
  });
  const [applyStyleModal, setApplyStyleModal] = useState<boolean>(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [msg, setMsg] = useState("");
  const { LAYOUT, AUTH } = useStore();
  const [dataV, setDataV] = useState();
  const [visible, setVisible] = useState<boolean>(false);

  const openStyleModal = (data: StyleProps) => {
    setStyleProps(data);
    setApplyStyleModal(true);
  };

  const closeStyleModal = () => {
    setApplyStyleModal(false);
  };

  useEffect(() => {
    if (LAYOUT.editValues && form.getFieldValue(`mix_slider`)?.[name]?.file) {
      setFileList([
        {
          uid: "-1",
          name: form.getFieldValue(`mix_slider`)?.[name]?.file,
          status: "done",
          url: createUrl("/secure/media/file/" + form.getFieldValue(`mix_slider`)?.[name]?.file, AUTH.awsS3Url),
        },
      ]);
    }
  }, []);

  useEffect(() => {
    if (layout_id && layoutIdFlag) {
      setFileList([]);
      const fieldValue = form.getFieldValue("mix_slider");
      fieldValue[`${name}`]["file"] = null;
      form.setFieldsValue({
        ["mix_slider"]: fieldValue
      });
    }
  }, [layout_id]);

  useEffect(() => {
    setMsg("");
    if ([87].includes(layout_id)) {
      setMsg(
        "Required Video Width = 1080 & Height = 1920"
      );
    } else if ([88]?.includes(layout_id)) {
      setMsg("Required Video Width = 1920 & Height = 1080");
    } else {
      setMsg("You can select mp4 video files for this widget");
    }
  }, [layout_id]);

  const onPreview = (file: any) => {
    const url = file.url ? file.url : URL.createObjectURL(file?.originFileObj);
    window.open(url);
  };

  //For showing modal on file removal :Start
  const toggle = () => {
    setVisible((prev) => !prev);
    return false;
  };

  const deleteItem = () => {
    if (typeof form.getFieldValue(`mix_slider`)?.[name]?.file !== "object") {
      const videoFieldId = LAYOUT.editValues.layout_field.find(
        (data: any) => data.shortcode === `mix_layout_${Number(name) + 1}_url`
      ).id;
      const payload = {
        file_path: "/storage/media/file/" + form.getFieldValue(`mix_slider`)?.[name]?.file,
        layout_field_id: videoFieldId,
      };
      LAYOUT.RemoveFileApi(LAYOUT.editValues.id, payload);
      toggle();
    }
    setFileList([]);
    const fieldValue = form.getFieldValue("mix_slider");
    fieldValue[`${name}`]["file"] = null;
    form.setFieldsValue({
      ["mix_slider"]: fieldValue
    });
    onChange();
    return true;
  };
  //For showing modal on file removal :End

  return (
    <>
      <Col>
        <Form.Item
          name={[name, "file"]}
          className="full-width-dragger"
          label={
            <div>
              {label.charAt(0).toUpperCase() + label.slice(1)}
              {AUTH.checkPlan(CONSTANT.INTERACTIVE_CONTENT_SUPPORT) ? (
                <Button
                  type="primary"
                  title="Apply style"
                  onClick={() =>
                    openStyleModal({ styles: widgetStyles.Video(), type: `mix_layout_${Number(name) + 1}` })
                  }
                  className="ml-5 form-button-icon"
                >
                  <FontAwesomeIcon icon={faPenNib} />
                </Button>
              ) : (
                <Popover title={() => planMessage()}>
                  <Button
                    type="primary"
                    title="Apply style"
                    disabled={true}
                    onClick={() =>
                      openStyleModal({
                        styles: widgetStyles.Video(),
                        type: `mix_layout_${Number(name) + 1}`,
                      })
                    }
                    className="ml-5 form-button-icon"
                  >
                    <FontAwesomeIcon icon={faPenNib} />
                  </Button>
                </Popover>
              )}
            </div>
          }
          extra={msg}
          rules={LayoutRequest.video}
          {...restField}
        >
          <Upload
            accept=".mp4"
            listType="text"
            multiple={false}
            fileList={fileList}
            onRemove={
              typeof form.getFieldValue(`mix_slider`)?.[name]?.file !== "object" ? toggle : deleteItem
            }
            onPreview={onPreview}
            showUploadList={{ showPreviewIcon: false }}
            onChange={({ fileList }: any) => {
              if (typeof form.getFieldValue(`mix_slider`)?.[name]?.file !== "object") {
                const layoutId = form.getFieldValue(["global_layout_id"]);
                const filePath =
                  "/storage/media/file/" + form.getFieldValue(`mix_slider`)?.[name]?.file;
                LAYOUT.RemoveFileApi(layoutId, filePath);
              }
              setFileList(fileList);

              const fieldValue = form.getFieldValue("mix_slider");
              fieldValue[`${name}`]["file"] = fileList[0]?.originFileObj;
              form.setFieldsValue({
                ["mix_slider"]: fieldValue
              });
              onChange();
            }}
            beforeUpload={(file: RcFile) => {
              const result = AUTH.checkPlanWObj(CONSTANT.SUPPORTED_VIDEO_SIZE);
              const planVideoSize = result[0].size;

              const isJpgOrPng = file.type === "video/mp4";
              const filegreater100MB = file.size / 1024 / 1024 > 100;
              const maxSize = file.size / 1024 / 1024 > planVideoSize;

              if (isJpgOrPng) {
                if (AUTH?.subscription?.type === 1 && filegreater100MB) {
                  message.error(
                    `You are on trial plan, you only allowed 100 MB of video to upload.`
                  );
                  message.error(
                    `Please upgrade to paid plan to remove 100 MB video limit.`
                  );
                  return Upload.LIST_IGNORE;
                } else if (maxSize) {
                  message.error(
                    `File size should not be more than ${planVideoSize} MB`
                  );
                  return Upload.LIST_IGNORE;
                } else {
                  setFileList([file]);
                  return false;
                }
              } else {
                message.error(` Upload valid video. Only MP4 format is allowed.`);
                return Upload.LIST_IGNORE;
              }
            }}
          >
            {fileList.length < 1 && (
              <Button icon={<UploadOutlined />}>Upload</Button>
            )}
          </Upload>
        </Form.Item>
        {applyStyleModal && (
          <Form.Item
            shouldUpdate={(prevValues, curValues) =>
              prevValues.layout_id !== curValues.layout_id
            }
          >
            <StyleModalComponent
              setDataV={setDataV}
              dataV={dataV}
              setShow={setShow}
              visible={applyStyleModal}
              close={closeStyleModal}
              styleProps={styleProps}
            />
          </Form.Item>
        )}
      </Col>
      <Modal
        okText="Yes"
        cancelText="No"
        open={visible}
        onOk={deleteItem}
        onCancel={toggle}
        className="removeUploadModal commanModal"
      >
        <h4>Are you sure you want to permanently remove this Item ?</h4>
      </Modal>
    </>
  );
});

export default MixVideoElement;
