import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { AgGridColumn } from "ag-grid-react";
import { Button, Image } from "antd";
import AgGridWrapper from "../../../../components/AgGridWrapper/AgGridWrapper";
import Config, { setCustomColumnFilter } from "../../../../config/Config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpAZ } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as DeleteIcon } from "../../../../assets/images/icon/delete.svg";
import { ReactComponent as EditIcon } from "../../../../assets/images/icon/edit.svg";
import { ReactComponent as PublishIcon } from "../../../../assets/images/icon/publish.svg";
import { ReactComponent as PreviewIcon } from "../../../../assets/images/icon/privewScreen.svg";
import { ReactComponent as SaveIcon } from "../../../../assets/images/icon/save.svg";
import { ReactComponent as CloneIcon } from "../../../../assets/images/icon/clone.svg";
import useStore from "../../../../store";
import { Notification } from "../../../../config/Global";
import { CONSTANT } from "../../../../config/Constant";

const ListComponent: React.FC<any> = observer((props) => {
  const {
    openEditModal,
    openDeleteModal,
    savedLayout,
    publishLayout,
    publishLayoutWithoutMedia,
    openPreviewDrawer,
    setFilterModal,
  } = props;

  const {
    LAYOUT,
    AUTH,
    LAYOUT: { 
      // deviceFilterList, 
      deviceFilter 
    },
  } = useStore();

  useEffect(() => {
    deviceFilter();
    LAYOUT.fetchList();
  }, []);

  const cellStyle = {
    textAlign: "center",
  };

  // const setDeviceColumnFilter = (params: any) => {
  //   const returnValue: any = ["empty"];
  //   params?.map((data: any) => {
  //     return returnValue.push({
  //       displayKey: data.name,
  //       displayName: data.name,
  //       predicate: (_: null, cellValue: string) =>
  //         cellValue != null && cellValue.includes(data.name),
  //       numberOfInputs: 0,
  //     });
  //   });

  //   return { filterOptions: returnValue };
  // };

  const cloneLayout = (data: any) => {
    LAYOUT.cloneLayout(data)
      .then((data: any) => {
        Notification.success({ message: data.data });
      })
      .catch((e: any) => {
        return Promise.reject(e);
      });
  };
  const orientationFilterValue = [
    { id: 1, value: "Portrait" },
    { id: 0, value: "Landscape" },
  ];

  const ActionRenderer: React.FC = (prop: any) => {
    return (
      <div className="action-column">
        {AUTH.checkPermission(CONSTANT.Employee, "preview") && (
          <Button
            data-tut="reactour__list_preview"
            type="text"
            title={"Preview Screen"}
            onClick={() => openPreviewDrawer(prop.data)}
          >
            <PreviewIcon />
          </Button>
        )}
         {![CONSTANT.SuperAdmin].includes(
            AUTH.user.users_roles_id
          )&& (
            <>
        {AUTH.checkPermission(CONSTANT.Employee, "Without media") &&
          prop.data.last_published_on && (
            <Button
              type="text"
              title={"Publish layout without media"}
              onClick={() => publishLayoutWithoutMedia(prop.data)}
            >
              <FontAwesomeIcon icon={faArrowUpAZ} color={Config.orangeBtn} />
            </Button>
          )}
        {AUTH.checkPermission(CONSTANT.Employee, "publish") 
         && (
            <Button
              data-tut="reactour__list_publish"
              type="text"
              title={"Publish this layout"}
              onClick={() => publishLayout(prop.data)}
            >
              <PublishIcon />
            </Button>
          )}
        {AUTH.checkPermission(5, "clone") 
        && (
            <Button
              key={3}
              className="ant-btn-text"
              title="Clone"
              onClick={() => cloneLayout(prop.data)}
            >
              <CloneIcon />
            </Button>
          )}
        {AUTH.checkPermission(CONSTANT.Employee, "save") 
          && (
            <Button
              type="text"
              title={"Save this layout"}
              onClick={() => savedLayout(prop.data)}
            >
              <SaveIcon />
            </Button>
          )}
        {AUTH.checkPermission(CONSTANT.Employee, "edit") 
        && (
            <Button
              data-tut="reactour__list_edit"
              type="text"
              title={"Edit"}
              className="editIcon"
              onClick={() => openEditModal(prop.data)}
            >
              <EditIcon />
            </Button>
          )}
        {AUTH.checkPermission(CONSTANT.Employee, "delete") 
          && (
            <Button
              type="text"
              title={"Delete"}
              onClick={() => openDeleteModal(prop.data)}
            >
              <DeleteIcon />
            </Button>
          )}
          </>
          )}
      </div>
    );
  };

  // function centerFormatter(params) {
  // 	return ((params.data?.center_users) ? params.data?.center_users.map((item, index) => {
  // 		return item?.name
  // 	}).join(", ") : Constant.UNDEFINED_VALUES)
  // }

  // const deviceCellrender = (params: any) => {
  // 	return (
  // 		params.data.device && params.data.device.length > 0 && params.data.device.map((it: any) => {
  // 			return it.name
  // 		}).join(' , ')
  // 	)
  // }

  const columnSetup = () => {
    return [
      <AgGridColumn
        key="id"
        data-tut="reactour__add_id"
        field="id"
        headerName="# ID"
        filter={"agNumberColumnFilter"}
        // pinned="left"
        cellStyle={cellStyle}
        // minWidth={90}
        tooltipField="id"
        maxWidth={80}
      />,
      <AgGridColumn
        key="layout_image"
        field="layout_image"
        sortable={false}
        filter={false}
        headerName="Layout Image"
        headerTooltip="Layout Image"
        cellStyle={cellStyle}
        minWidth={130}
        maxWidth={130}
        cellRendererFramework={(data: any) => {
          return (
            <Image
              src={
                data?.data?.global_layout?.image &&
                require("../../../../assets/images/layouts_default_image/" +
                  data?.data?.global_layout?.image)
              }
              rootClassName={data?.data?.oriantation ?? "Landscape"}
            />
          );
        }}
      />,
      <AgGridColumn
        key="name"
        field="name"
        headerName="Layout Name"
        tooltipField="name"
        minWidth={100}
      />,
      <AgGridColumn
        key="device_name"
        field="device_name"
        headerName="Devices"
        minWidth={280}
        // filterParams={setDeviceColumnFilter(deviceFilterList)}
      />,
      <AgGridColumn
        key="description"
        field="description"
        headerName="Description"
        tooltipField="description"
        maxWidth={180}
      />,
      <AgGridColumn
        key="oriantation"
        field="oriantation"
        headerName="Orientation"
        filter="agTextColumnFilter"
        tooltipField="oriantation"
        filterParams={setCustomColumnFilter(orientationFilterValue)}
        maxWidth={130}
      />,
      <AgGridColumn
        key="users.username"
        field="users.username"
        minWidth={200}
        tooltipField="users.username"
        width={250}
        maxWidth={200}
        headerName="Created by"
      />,
      <AgGridColumn
        key="action"
        headerName="Actions"
        type="actionColumn"
        sortable={false}
        filter={false}
        maxWidth={250}
        minWidth={250}
        pinned="right"
      />,
    ];
  };

  return (
    <AgGridWrapper
      onGridReady={LAYOUT.setupGrid}
      components={{ ActionRenderer }}
      rowData={LAYOUT.list_data}
      onColumnResized={LAYOUT.onGridChanged}
      onColumnMoved={LAYOUT.onGridChanged}
      onFilterChanged={(props) => {
        setFilterModal(props.api.getFilterModel());
      }}
    >
      {columnSetup()}
    </AgGridWrapper>
  );
});

export default ListComponent;
