import { Button, Checkbox, Col, Drawer, Form, Row, Spin, Table, Image } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { observer } from 'mobx-react'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { FormBox, InputBox } from '../../../../components/AntdAddons'
import { layoutPublish } from '../../../../requests/PageRequest'
import useStore from '../../../../store'
import { componentProps } from '../../../../store/RateStore/RateInterface'
import { displayFormats, Notification, validateFields } from '../../../../config/Global'
import { ErrorProps } from '../../../../store/RootStore/RootInterface'
import no_image from '../../../../assets/images/no_image.jpg'
import { CONSTANT } from '../../../../config/Constant'
interface DataType {
	key: React.Key;
	days: string;
	start_time: any;
	end_time: any;
}
const PublishLayout: React.FC<componentProps> = observer((props: componentProps) => {
	const { visible, close } = props
	const [disabled, setDisabled] = useState<boolean>(true)
	const [deviceList, setDeviceList] = useState<boolean>(true)
	const [existingSchedule, setExistingSchedule] = useState<boolean>(false)
	const [saving, setSaving] = useState<boolean>(false)
	const [form] = Form.useForm()
	const { AUTH, LAYOUT, ROOT } = useStore()
	const { assignErrorToInput } = ROOT
	const [tableData, setTableData] = useState<any>(null)
	const selectTimeZoneList: any = (AUTH.user.company_id ? AUTH.checkPlan(CONSTANT.ADVANCE_SCHEDULING) : true) ? [{ id: 1, name: 'Publish Now', disable: false }, { id: 2, name: 'Schedule Now', disable: false }] : [{ id: 1, name: 'Publish Now', disable: false }, { id: 2, name: 'Schedule Now', disable: true }]
	// const selectTimeZoneList:any = [{ id: 1, name: 'Publish Now' }, { id: 2, name: 'Schedule Now'}]
	const closeModal = () => {
		close()
		setTableData(null)
		setDisabled(true)
		form.resetFields()
	}
	const handleSubmit = (data: any) => {
		data.id = visible.id
		const start_time: any = []
		const end_time: any = []
		setSaving(true)
		if (data.type === 2 && tableData) {
			data.week_day = tableData
		}
		if (data.schedule_type === 1) {
			data.start_time = moment(data.start_date).format(displayFormats.TIME_FORMAT_Y)
			data.end_time = moment(data.end_date).format(displayFormats.TIME_FORMAT_Y)
			data.start_date = moment(data.start_date).format(displayFormats.DATE_FORMAT_Y)
			data.end_date = moment(data.end_date).format(displayFormats.DATE_FORMAT_Y)
			delete data?.schedule_type
			delete data['week_day']
			data.type = 1
		} else if (data.schedule_type === 2) {
			Object.keys(data).map((_x) => {
				if (_x.split('_')[1] === 'start' && _x.split('_')[2] === 'time') {
					start_time.push(moment(data[_x]).format(displayFormats.TIME_FORMAT_Y))
					delete data[_x]
				}
				if (_x.split('_')[1] === 'end' && _x.split('_')[2] === 'time') {
					end_time.push(moment(data[_x]).format(displayFormats.TIME_FORMAT_Y))
					delete data[_x]
				}
				data.start_time = start_time
				data.end_time = end_time
				data.type = 2
				delete data?.schedule_type
			})
		} else {
			data.type = existingSchedule ? 3 : 0
		}
		const finalPayload = { ...data }
		LAYOUT.publishLayout(finalPayload).then((data: any) => {
			Notification.success({ message: data.data })
			setExistingSchedule(false)
			close()
			form.resetFields();
		}).catch((e: ErrorProps) => {
			assignErrorToInput(form, e?.data.NOTIFICATION);
		}).finally(() => { setSaving(false) })
	}

	const handleChange = () => {
		validateFields(form, setDisabled);
	}

	useEffect(() => {
		const dataV: any = visible && visible?.device.map((it: any) => {
			return it.id
		})
		form.setFieldsValue({
			device_id: dataV
		})
		var new_list: any = []
		visible && visible?.device.map((it: any) => {
			return new_list.push({ id: it.id, name: it.name })
		})
		LAYOUT.DeviceData = new_list
		setDisabled(false)
	}, [visible])

	useEffect(() => {
		if (existingSchedule) {
			setDisabled(false)
		}
	}, [existingSchedule])

	const rowSelection = {
		onChange: (selectedRowKeys: any, selectedRows: DataType[]) => {
			selectedRows;
			setTableData(selectedRowKeys);
			if (selectedRows.length > 0) {
				setDisabled(false)
			} else {
				setDisabled(true)
			}
		},
		columnTitle: <></>
	};

	const columns: ColumnsType<DataType> = [
		{
			title: 'Days',
			dataIndex: 'days',
			render: (text: string) => <a>{text}</a>,
			key: 'days'
		},
		{
			title: 'Start Time',
			dataIndex: 'start_time',
			key: 'start_time'
		},
		{
			title: 'End Time',
			dataIndex: 'end_time',
			key: 'end_time'
		},
	];

	const handleTimezonChange = () => {
		setDisabled(true)
		if (form.getFieldValue('schedule_type') === 1) {
			setTableData([]);
			form.resetFields(['mon_start_time', 'mon_end_time', 'tue_start_time', 'tue_end_time', 'wed_start_time', "wed_end_time", 'thu_start_time', 'thu_end_time', 'fri_start_time', 'fri_end_time', 'sat_start_time', 'sat_end_time', "sun_start_time", 'sun_start_time'])
		} else {
			form.resetFields(['start_date', 'end_date'])
		}
	};

	const handleTimeChange = () => {
		setDisabled(true)
		if (form.getFieldValue('type') === 1) {
			setDisabled(false)
			setTableData([])
			form.resetFields(['mon_start_time', 'mon_end_time', 'tue_start_time', 'tue_end_time', 'wed_start_time', "wed_end_time", 'thu_start_time', 'thu_end_time', 'fri_start_time', 'fri_end_time', 'sat_start_time', 'sat_end_time', "sun_start_time", 'sun_start_time'])

		} else {
			form.resetFields(['start_date', 'end_date'])
		}
	}
	const disableMonStartDate = (current: any) => {
		return current && current <= moment(moment().add(60, 'minute').format(displayFormats.TIME_FORMAT_Y));
	}
	const disableMonEndDate = (current: any) => {
		return (form.getFieldValue('mon_start_time') ? current && current <= moment(form.getFieldValue('mon_start_time')) : current && current < moment(moment().add(60, 'minute').format(displayFormats.TIME_FORMAT_Y)));
	}

	const disableTueEndDate = (current: any) => {
		return (form.getFieldValue('tue_start_time') ? current && current <= moment(form.getFieldValue('tue_start_time')) : current && current < moment(moment().add(60, 'minute').format(displayFormats.TIME_FORMAT_Y)));
	}

	const disableWedEndDate = (current: any) => {
		return (form.getFieldValue('wed_start_time') ? current && current <= moment(form.getFieldValue('wed_start_time')) : current && current < moment(moment().add(60, 'minute').format(displayFormats.TIME_FORMAT_Y)));
	}

	const disableThuEndDate = (current: any) => {
		return (form.getFieldValue('thu_start_time') ? current && current <= moment(form.getFieldValue('thu_start_time')) : current && current < moment(moment().add(60, 'minute').format(displayFormats.TIME_FORMAT_Y)));
	}
	const disableFriEndDate = (current: any) => {
		return (form.getFieldValue('fri_start_time') ? current && current <= moment(form.getFieldValue('fri_start_time')) : current && current < moment(moment().add(60, 'minute').format(displayFormats.TIME_FORMAT_Y)));
	}
	const disablesatEndDate = (current: any) => {
		return (form.getFieldValue('sat_start_time') ? current && current <= moment(form.getFieldValue('sat_start_time')) : current && current < moment(moment().add(60, 'minute').format(displayFormats.TIME_FORMAT_Y)));
	}
	const disablesunEndDate = (current: any) => {
		return (form.getFieldValue('sun_start_time') ? current && current <= moment(form.getFieldValue('sun_start_time')) : current && current < moment(moment().add(60, 'minute').format(displayFormats.TIME_FORMAT_Y)));
	}
	const data: DataType[] = [
		{
			key: '0',
			days: 'MON',
			start_time: tableData && tableData.includes('0') ? <InputBox.TimePicker allowClear={false} rules={layoutPublish.dateTime}
				onChange={(e: any) => {
					{ form.setFieldsValue({ 'mon_start_time': moment(e), 'mon_end_time': moment(e.add(10, 'minute')) }) }
				}} disabledDate={disableMonStartDate} name={'mon_start_time'} format={displayFormats.TIME_FORMAT_Y}></InputBox.TimePicker> : null,
			end_time: tableData && tableData.includes('0') ? <InputBox.TimePicker allowClear={false} rules={layoutPublish.dateTime}
				name={'mon_end_time'} format={displayFormats.TIME_FORMAT_Y} disabledDate={disableMonEndDate}></InputBox.TimePicker> : null
		},
		{
			key: '1',
			days: 'TUE',
			start_time: tableData && tableData.includes('1') ? <InputBox.TimePicker disabledDate={disableMonStartDate} allowClear={false} rules={layoutPublish.dateTime} onChange={
				(e: any) => {
					{ form.setFieldsValue({ 'tue_start_time': moment(e), 'tue_end_time': moment(e.add(10, 'minute')) }) }
				}
			} name={'tue_start_time'} format={displayFormats.TIME_FORMAT_Y}></InputBox.TimePicker> : null,
			end_time: tableData && tableData.includes('1') ? <InputBox.TimePicker allowClear={false} disabledDate={disableTueEndDate} rules={layoutPublish.dateTime} name={'tue_end_time'} format={displayFormats.TIME_FORMAT_Y}></InputBox.TimePicker> : null,
		},
		{
			key: '2',
			days: 'WED',
			start_time: tableData && tableData.includes('2') ? <InputBox.TimePicker disabledDate={disableMonStartDate} allowClear={false} rules={layoutPublish.dateTime} onChange={
				(e: any) => {
					{ form.setFieldsValue({ 'wed_start_time': moment(e), 'wed_end_time': moment(e.add(10, 'minute')) }) }
				}
			} name={'wed_start_time'} format={displayFormats.TIME_FORMAT_Y}></InputBox.TimePicker> : null,
			end_time: tableData && tableData.includes('2') ? <InputBox.TimePicker allowClear={false} disabledDate={disableWedEndDate} rules={layoutPublish.dateTime} name={'wed_end_time'} format={displayFormats.TIME_FORMAT_Y}></InputBox.TimePicker> : null,
		},
		{
			key: '3',
			days: 'THU',
			start_time: tableData && tableData.includes('3') ? <InputBox.TimePicker disabledDate={disableMonStartDate} allowClear={false} rules={layoutPublish.dateTime} onChange={
				(e: any) => {
					{ form.setFieldsValue({ 'thu_start_time': moment(e), 'thu_end_time': moment(e.add(10, 'minute')) }) }
				}
			} name={'thu_start_time'} format={displayFormats.TIME_FORMAT_Y}></InputBox.TimePicker> : null,
			end_time: tableData && tableData.includes('3') ? <InputBox.TimePicker allowClear={false} disabledDate={disableThuEndDate} rules={layoutPublish.dateTime} name={'thu_end_time'} format={displayFormats.TIME_FORMAT_Y}></InputBox.TimePicker> : null,
		},
		{
			key: '4',
			days: 'FRI',
			start_time: tableData && tableData.includes('4') ? <InputBox.TimePicker disabledDate={disableMonStartDate} allowClear={false} rules={layoutPublish.dateTime} onChange={
				(e: any) => {
					{ form.setFieldsValue({ 'fri_start_time': moment(e), 'fri_end_time': moment(e.add(10, 'minute')) }) }
				}
			} name={'fri_start_time'} format={displayFormats.TIME_FORMAT_Y}></InputBox.TimePicker> : null,
			end_time: tableData && tableData.includes('4') ? <InputBox.TimePicker allowClear={false} disabledDate={disableFriEndDate} rules={layoutPublish.dateTime} name={'fri_end_time'} format={displayFormats.TIME_FORMAT_Y}></InputBox.TimePicker> : null,
		}, {
			key: '5',
			days: 'SAT',
			start_time: tableData && tableData.includes('5') ? <InputBox.TimePicker disabledDate={disableMonStartDate} allowClear={false} rules={layoutPublish.dateTime} onChange={
				(e: any) => {
					{ form.setFieldsValue({ 'sat_start_time': moment(e), 'sat_end_time': moment(e.add(10, 'minute')) }) }
				}
			} name={'sat_start_time'} format={displayFormats.TIME_FORMAT_Y} ></InputBox.TimePicker> : null,
			end_time: tableData && tableData.includes('5') ? <InputBox.TimePicker allowClear={false} disabledDate={disablesatEndDate} rules={layoutPublish.dateTime} name={'sat_end_time'} format={displayFormats.TIME_FORMAT_Y}></InputBox.TimePicker> : null,
		}, {
			key: '6',
			days: 'SUN',
			start_time: tableData && tableData.includes('6') ? <InputBox.TimePicker disabledDate={disableMonStartDate} allowClear={false} rules={layoutPublish.dateTime} onChange={
				(e: any) => {
					{ form.setFieldsValue({ 'sun_start_time': moment(e), 'sun_end_time': moment(e.add(10, 'minute')) }) }
				}
			} name={'sun_start_time'} format={displayFormats.TIME_FORMAT_Y}></InputBox.TimePicker> : null,
			end_time: tableData && tableData.includes('6') ? <InputBox.TimePicker allowClear={false} disabledDate={disablesunEndDate} rules={layoutPublish.dateTime} name={'sun_end_time'} format={displayFormats.TIME_FORMAT_Y}></InputBox.TimePicker> : null,
		},
	];
	const disableStartDate = (current: any) => {
		return current && current < moment(moment().add(24, 'hours').subtract(1, 'day').format("YYYY-MM-DD HH:mm"));
	}

	const disableEndDate = (current: any) => {
		return (form.getFieldValue('start_date') ? current && current <= moment(form.getFieldValue('start_date')) : current && current < moment(moment().add(24, 'hours').format("YYYY-MM-DD HH:mm")));
	}

	const handleDateChange = (e: any) => {
		setDisabled(false)
		form.setFieldsValue({ 'end_date': moment(e).add(10, 'minutes') })
	}
	return (
		<>
			<Drawer
				title="Publish Layout"
				width={"45%"}
				open={visible}
				destroyOnClose
				className="commanDrawer editLicense publishLayout"
				onClose={closeModal}
				footer={[
					<Button key={2} form="publishLayouform" className={`${disabled ? 'disableBtn' : 'blackLineBtn'}`} disabled={disabled} loading={saving} htmlType="submit" type="primary">Publish</Button>,
					<Button key={1} onClick={closeModal} className="orangeBgBtn cancelBtn">Cancel</Button>,
				]}
			>
				<FormBox form={form} id="publishLayouform" onFinish={handleSubmit} initialValues={{ type: 1 }}>
					<Row align="middle" gutter={24} >
						<Col span={12}>
							<InputBox.Select
								mode='multiple'
								maxTagCount={2}
								filterOption={(input: any, option: any) =>
									(option?.children || {}).toLowerCase().includes(input.toLowerCase())}
								name="device_id"
								label="Select Device" placeholder='Select Device'
								onFocus={(() => deviceList && LAYOUT.getDevice().then(() => setDeviceList(true)))}
								notFoundContent={LAYOUT.DeviceData?.length > 0 ? <Spin /> : 'No data found'}
								rules={layoutPublish.device}
								allowClear
								onChange={(e: any) => {
									if (e && e.length > 0 && e.includes("")) {
										e = LAYOUT.DeviceData.map((obj: any) => obj.id);
									}

									form.setFieldsValue({
										device_id: e
									})
									handleChange()
								}}
								options={{
									list: LAYOUT.DeviceData && LAYOUT.DeviceData?.length > 0 ? [...[{ 'id': '', 'name': 'Select All' }], ...LAYOUT.DeviceData] : [],
									valueKey: 'id',
									textKey: 'name'
								}}
							/>
						</Col>
						<Col span={12}>
							<InputBox.Radio
								required
								label='Select TimeZone'
								buttonStyle='solid'
								onChange={() => {
									handleTimeChange()
								}}
								optionType='button'
								options={{
									list: selectTimeZoneList && selectTimeZoneList,
									disabled_keys:
										selectTimeZoneList &&
										selectTimeZoneList
											.filter((item: any) => item.disable === true)
											.map((item: any) => item.id),
								}}
								name='type'

							/>
						</Col>
						{/* <Col span={12}>
					<Form.Item shouldUpdate={(prevValues, curValues) => (prevValues.type !== curValues.type)} style={{ paddingLeft: "12px" }}>
							{() => {
								if (form.getFieldValue('type') === 2) {
									return (
										<Col span={24}>
											<InputBox.Radio
												// required
												label='Select TimeZone'
												buttonStyle='solid'
												onChange={handleTimezonChange}
												optionType='button'
												options={{
													list: [{ id: 1, name: 'Once' }, { id: 2, name: 'Repeat' }],
												}}
												name='schedule_type'
											/>
										</Col>
									)
								}
							}}
						</Form.Item>
					</Col> */}
						{/* <Col span={12} className='imgCol'> */}
						<Col span={12} className='imgCol'>
							<Form.Item shouldUpdate={(prevValues, curValues) => (prevValues.type !== curValues.type)} style={{ paddingLeft: "12px" }}>
								{() => {
									if (form.getFieldValue('type') === 2) {
										return (
											<Col span={24}>
												<InputBox.Radio
													// required
													label='Select TimeZone'
													buttonStyle='solid'
													onChange={handleTimezonChange}
													optionType='button'
													options={{
														list: [{ id: 1, name: 'Once' }, { id: 2, name: 'Repeat' }],
													}}
													name='schedule_type'
												/>
											</Col>
										)
									}
								}}
							</Form.Item>
						</Col>
						<Col span={8} className='imgCol'>
							<Image src={(visible.global_layout && visible.global_layout.image !== undefined) ? require('../../../../assets/images/layouts_default_image/' + visible.global_layout.image) : no_image} />
						</Col>
					</Row>
					<Row>
						<Col span={24}>
							<Form.Item shouldUpdate={(prevValues, curValues) => (prevValues.type !== curValues.type) || (prevValues.schedule_type !== curValues.schedule_type)}>
								{() => {
									if (form.getFieldValue('type') === 1 && AUTH.user.company_id !== 5 && AUTH.checkPermission(6, "override")) {
										return (
											<Row gutter={24}>
												<Col span={12}>
													<Checkbox name='override' checked={existingSchedule} onChange={() => setExistingSchedule(!existingSchedule)}>Override Existing Schedule</Checkbox>
												</Col>
											</Row>
										)
									} else {
										if (form.getFieldValue('schedule_type') === 2) {
											return (
												<Table
													style={{ width: '100%' }}
													rowSelection={rowSelection}
													pagination={false}
													columns={columns}
													dataSource={data}
												/>
											)
										}
										if (form.getFieldValue('schedule_type') === 1) {
											return (
												<Row gutter={24} className='onceTypeDate'>
													<Col span={12}>
														<InputBox.DateTimePicker label='Start Date & Time' format={displayFormats.DATE_FORMAT} style={{ width: '100%' }} rules={layoutPublish.dateTime} allowClear={false} onChange={(e: any) => {
															// handleChange()
															handleDateChange(e)
														}} disabledDate={disableStartDate} name={'start_date'}></InputBox.DateTimePicker>
													</Col>
													<Col span={12}>
														<InputBox.DateTimePicker label='End Date & Time' format={displayFormats.DATE_FORMAT} rules={layoutPublish.dateTime} name={'end_date'} allowClear={false} disabledDate={disableEndDate} onChange={handleChange}></InputBox.DateTimePicker>
													</Col>
												</Row>
											)
										}
									}
								}}
							</Form.Item>
						</Col>
					</Row>
				</FormBox>
			</Drawer>
		</>
	)
})

export default PublishLayout