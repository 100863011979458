export const CONSTANT = {
  BASE_URL: process.env.REACT_APP_API_URL,
  LOGIN_REDIRECT_PATH: "/login",
  DASHBOARD_REDIRECT_PATH: "/dashboard",
  WITHOUT_LOGIN_PAGE: ["/", "/login"],
  PER_PAGE_RECORD: [
    { id: "50", value: "50 per page" },
    { id: "100", value: "100 per page" },
    { id: "500", value: "500 per page" },
    { id: "1000", value: "1000 per page" },
  ],
  ORIANTATION: {
    0: "Landscape",
    1: "Portrait",
  },
  MSG: {},

  ORIANTATION_VALUE: [
    { id: 1, value: "Portrait" },
    { id: 0, value: "Landscape" },
  ],

  USERPERMISSION: {
    is_custom0: 0,
    is_custom1: 1,
  },
  SuperAdmin: 1,
  SubAdmin: 2,
  Company: 3,
  Employee: 4,
  Partner: 5,

  SIDEBAR: {
    Dashboard: 1,
    Users: 2,
    RolesManagement: 3,
    Layouts: 4,
    SavedLayouts: 5,
    Devices: 6,
    Rates: 7,
    GlobalPopup: 8,
    Settings: 9,
    Tokens: 10,
    Schedule: 11,
    GroupDevices: 12,
    AuditLogs: 13,
    UpdateAPK: 14,
    Subscription: 15,
    SubscriptionList: 16,
    Analytics: 17
  },

  STORE_TYPES: {
    CloudyfyS3: 1,
    CompanyS3: 2,
    LocalS3: 3,
  },

  MIX_SLIDER: {
    IMAGE: 1,
    VIDEO: 2
  },

  SCHEDULE: {
    ALL_DEVICES: 0,
    TYPE: {
      ONCE: 1,
      REPEAT: 2,
      OVERRIDE: 3
    },
    WEEK_DAYS: [
      { id: 0, value: "Monday" },
      { id: 1, value: "Tuesday" },
      { id: 2, value: "Wednesday" },
      { id: 3, value: "Thursday" },
      { id: 4, value: "Friday" },
      { id: 5, value: "Saturday" },
      { id: 6, value: "Sunday" },
    ]
  },

  // FOR THE PLAN WISE PERMISSIONS
  BUILD_YOUR_OWN_LAYOUT: "build_your_own_layout",
  SUPPORTED_VIDEO_SIZE: "supported_video_size",
  LIVE_BROADCAST: "youtube_video",
  SOCIAL_LAYOUTS: "integration_with_social_media_platforms",
  CONTENT_COLLAB_AND_SHARING: "content_collaboration_and_sharing",
  LAST_ONLINE_TIME: "last_online_time",
  PROOF_OF_PLAY: "menu_proof_of_play",
  AUTO_RESTART_DEVICES: "auto_restart_devices",
  CUSTOM_SCALING: "custom_scaling",
  INT_WITH_SOCIAL_MEDIA: "integration_with_social_media_platforms",
  GLOBAL_ANNOUNCEMENTS: "global_announcements",
  ADVANCE_SCHEDULING: "menu_advanced_scheduling",
  USER_ACCESS_CONTROL: "menu_user_access_control",
  DEVICES_SPECIFIC_ACCESS: "device_specific_access",
  INTERACTIVE_CONTENT_SUPPORT: "interactive_content_support",
  AUTO_PLAY: "auto_play_image_slides_in_random_order",

  // PLAN_MESSAGE: <div><UpgradeIcon /><Link to={"/plan"}>Please Upgrade Your Plan</Link></div> ,

};

