import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { InputBox } from "../../../../components/AntdAddons";
import { LayoutRequest } from "../../../../requests/PageRequest";
import { Col, FormInstance } from "antd";

interface formInterface {
  form: FormInstance;
  name: string;
  onChange: () => void;
  layout_id: any;
  layoutIdFlag?: boolean;
}

const WebsitePreview: React.FC<formInterface> = observer(
  (props: formInterface) => {
    const { name, onChange, layout_id, form, layoutIdFlag } = props;
    const [label, setLabel] = useState<string>("");
    const [msg, setMsg] = useState<string>("");

    useEffect(() => {
      if (layout_id && layoutIdFlag) {
        form.setFieldValue(name, "");
      }
    }, [layout_id]);

    useEffect(() => {
      setMsg("");
      if ([86]?.includes(layout_id)) {
        setLabel("URL")
        setMsg("This widget will set the website URL.");
      } else if ([89]?.includes(layout_id)) {
        setLabel("Teams Public Event Url")
        setMsg("This widget will set the Teams Public Event Url.");
      }
    }, [])

    return (
      <Col>
        <InputBox.Text
          name={name}
          label={
            <div>
              {label.charAt(0).toUpperCase() + label.slice(1)}
            </div>
          }
          placeholder={`Enter ${label.charAt(0).toUpperCase() + label.slice(1)
            }`}
          rules={LayoutRequest.website_preview}
          onChange={onChange}
          extra={msg}
        />
      </Col>
    );
  }
);

export default WebsitePreview;
