import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Drawer, Form, Spin } from 'antd';
import FormComponent from './FormComponent';
import useStore from '../../../../store';
import { componentProps } from '../../../../store/UserRoleStore/UserRoleInterface';
import { Notification, displayFormats, validateFields } from '../../../../config/Global';
import { ErrorProps } from '../../../../store/RootStore/RootInterface';
import { Regex } from '../../../../config/Validators';
import moment from 'moment';
import { CONSTANT } from '../../../../config/Constant';

const EditComponent: React.FC<componentProps> = observer((props: componentProps) => {
	const { visible, close } = props
	const { USER, ROOT } = useStore()
	const [form] = Form.useForm()
	const [disabled, setDisabled] = useState<boolean>(true)
	const [saving, setSaving] = useState<boolean>(false)

	const handleClose = () => {
		close()
		setDisabled(true)
		form.resetFields()
	}

	const handleChange = () => {
		var data = form.getFieldsValue()
		form.setFieldsValue({
			phone1: data.phone1 && data.phone1.replace(Regex.mobilespace, '$1 $2 $3'),
			phone2: data.phone2 && data.phone2.replace(Regex.mobilespace, '$1 $2 $3')
		})
		validateFields(form, setDisabled);
	}
	const handleSubmit = (data: any) => {
		setSaving(true)
		data.user_id = USER.viewValues?.id
		data.company_id = USER.viewValues?.company_id
		data.from_date = moment(data.from_date).format(displayFormats.TEMP_DATE_FORMAT)
		data.to_date = moment(data.to_date).format(displayFormats.TEMP_DATE_FORMAT)
		if ([CONSTANT.STORE_TYPES.CompanyS3, CONSTANT.STORE_TYPES.LocalS3].includes(data.store_type)) {
			data.aws_bucket = data.aws_bucket ?? USER.aws_bucket?.['aws_bucket']
			data.aws_default_region = data.aws_default_region ?? USER.aws_bucket?.['aws_default_region']
			data.aws_secret_access_key = data.aws_secret_access_key ?? USER.aws_bucket?.['aws_secret_access_key']
			data.aws_access_key_id = data.aws_access_key_id ?? USER.aws_bucket?.['aws_access_key_id']
		}
		if ([CONSTANT.STORE_TYPES.LocalS3].includes(data.store_type)) {
			data.host_url = data.host_url ?? USER.aws_bucket?.['host_url']
		}
		USER.EditData(data).then((data: any) => {
			setSaving(false)
			Notification.success({
				message: data.message
			})
			handleClose()
		}).catch((e: ErrorProps) => {
			ROOT.assignErrorToInput(form, e?.data.STATUS);
		}).finally(() => setSaving(false));
	}

	return (
		<Drawer
			open={visible}
			onClose={handleClose}
			width={750}
			className='commanDrawer editUser'
			title={`Edit User`}
			destroyOnClose={true}
			footer={[
				<div key="1">
					<Button disabled={disabled} className={`${disabled ? 'disableBtn' : 'blackLineBtn'}`} form='addeditform' loading={saving} htmlType="submit" type="primary" >Save</Button>
					<Button className="orangeBgBtn cancelBtn" onClick={handleClose}>Cancel</Button>
				</div>
			]}
		>
			<Spin size="large" spinning={!USER.viewValues} tip={"Loading"}>
				<FormComponent type='edit' form={form} handleSubmit={handleSubmit} onChange={handleChange} />
			</Spin>
		</Drawer>
	)
})

export default EditComponent  